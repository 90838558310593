@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

@font-face {
  font-family: 'summa-icons';
  src: url('../fonts/summa-icons/summa-icons.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: auto
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter/inter-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter/inter-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter/inter-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

body {
  color: #4c4c4c;
  font-family: 'Inter','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  font-size: 14px;
}

.App {
  text-align: center;
}
.blockquote-footer::before {
  content: "" !important;
}
.title-ppal {
  text-align: center;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 300;
}
.title-desc {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  text-align: center;
  color: #8d8d8d;
  /*   opacity: 70%; 
  margin-bottom: 1.5rem;*/
}
.title-rut {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}
.title-rut span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ff0000;
}

.title-boleta {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}
.ingresa-rut {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  text-align: left;
  color: #8d8d8d;
}
.ingresa-boleta,
.desc-rut,
.desc-monto {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  text-align: left;
  color: #8d8d8d;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.img-mob {
  background-image: url(../img/ROSENMOBILE.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
}
.img-logo-mob {
  background-image: url(../img/LogoMob.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 24px;
}
.btn-color {
  background-color: #4c4c4c !important;
  color: #ffffff !important;
  border-radius: 0% !important;
  width: 170px;
  height: 35px;
  line-height: 2px !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 12px !important;
}
.footerStyle {
  background-color: #4c4c4c !important;
  border-radius: 0% !important;
}

.float-label-control {
  position: relative;
  margin-bottom: 0.5em;
}

.float-label-control input,
.float-label-control textarea,
.float-label-control label {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.float-label-control textarea:focus {
  padding-bottom: 4px;
}
.float-label-control input,
.float-label-control textarea {
  display: block;
  width: 100%;
  padding: 0.1em 0em 12px 0em;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #aaa;
  outline: none;
  margin: 0px;
  background: none;
}

@media only screen and (min-width: 321px) {
  .title-ppal {
    font-size: 27px;
  }
  .title-desc {
    font-size: 12px;
  }
}
@media only screen and (min-width: 375px) {
  .img-mob {
    background-size: cover;
  }
}
@media only screen and (min-width: 411px) {
}

@media only screen and (min-width: 568px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .img-mob {
    height: 330px;
  }
}

@media only screen and (min-width: 800px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1023px) {
  .img-logo-mob {
    background-image: url(../img/LogoDesk.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 24px;
  }

  .img-mob {
    background-image: url(../img/ROSENDESK.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30rem;
  }
  .title-ppal {
    text-align: left;
    font-size: 20px;
  }
  .title-desc {
    text-align: left;
  }
  .float-label-control input,
  .float-label-control textarea {
    display: block;
    width: 67%;
  }
}
@media only screen and (min-width: 1200px) {
  .title-ppal {
    font-size: 26px;
  }
  .float-label-control input,
  .float-label-control textarea {
    display: block;
    width: 40%;
  }
}
@media only screen and (min-width: 1366px) {
  .img-mob {
    height: 40rem;
  }
  .float-label-control input,
  .float-label-control textarea {
    display: block;
    width: 40%;
  }
}
@media only screen and (min-width: 1440px) {
  .img-mob {
    height: 40rem;
  }
}
@media only screen and (min-width: 1560px) {
  .img-mob {
    height: 68rem;
  }
}
@media only screen and (min-width: 1600px) {
  .img-mob {
    height: 57rem;
  }
}
@media only screen and (min-width: 1920px) {
  .img-mob {
    height: 68rem;
  }
}

div.mage-error {
  color: #de1414;
  font-weight: 300;
  line-height: 16px;
  font-size: 12px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  letter-spacing: normal;
}



